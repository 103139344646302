.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y
}

.owl-carousel .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0,0,0)
}

.owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%
}

.owl-carousel .owl-wrapper-outer.autoHeight {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out
}

.owl-carousel .owl-item {
    float: left
}

.owl-controls .owl-page,.owl-controls .owl-buttons div {
    cursor: pointer
}

.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(168, 156, 156, 0)
}


.owl-carousel .owl-wrapper,.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0)
}

.slider-main .carousel-caption{
    right: 5%;
    bottom: 25%;
    left: 5%;
    padding: 0;
}

.slider-main .carousel .carousel-item .carousel-caption h3 {
    font-size: 40px;
}

.slider-main .carousel .carousel-item .carousel-caption p {
    font-size: 18px;
}

.slick-slide img {
    display: inline-block;
}

.touch-line {
    background: #1273EB !important;
}

@media only screen and (max-width: 600px) {
    .slider-main .carousel-caption {
       bottom: 15%
    }
}