.product-detail .list-group-item .header {
font-weight: 600;
}

.product-detail .react-tabs {
    width: 100%;
}

.left-ab h3 {
    font-size: 32px !important;
}