
 .about-main{
    padding: 30px 0px;
  }

  .about-main h5{
    font-size: 18px;
  }
  .about-main p{
    font-size: 14px;
    font-weight: 400;
  }
  .about-main ul{
    padding-left: 0px;
  }
  .about-main ul li{
    position: relative;
    font-weight: 300;
    list-style: none;
    line-height: 29px;
    font-size: 15px;
    padding-bottom:10px; 
  }
  .about-main ul li:before{
    /* content: "\f0da"; */
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;	
    position: absolute;
    left: -15px;
    top: 0px;
    color: #1273eb;
  }

  .card-header-image {
    text-align: center;
    padding-bottom: 1em;
  }

  .our-team .card-header{
    margin-bottom: 5px !important;
  }